:root {
  --max-body-width: 736px;
  --basic-font-size: 16px;
  --main-accent-color: #20be43;
  --text-color: #202020;
  --inverted-text-color: #ffffff;
  --secondary-text-color: #8b8b8b;
  --tertiary-text-color: #b8b8b8;
  --main-background-color: #ffffff;
  --alt-background-color: #f6f6f6;
  --danger-color: #ff3434;
  --border-color: #e5e5e5;
  --shadow-color: rgba(0, 5, 10, 0.1);
  --rating-star-color: #ffd153;

  --general-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

* {
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  width: 100%;
  background: var(--alt-background-color);
  touch-action: manipulation;
  -webkit-text-size-adjust: none;
}

body {
  position: relative;
  width: 100%;
  max-width: var(--max-body-width);
  margin: 0 auto;
  overflow-x: hidden;
  color: var(--text-color);
  font-family: var(--general-font);
  font-size: var(--basic-font-size);
  background: var(--main-background-color);
  box-shadow: 0 0 20px var(--shadow-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: 100%;
  height: auto;
}

button {
  user-select: none;
  touch-action: manipulation;
}

#root {
  width: 100%;
}

body,
.smooth-scroll {
  scroll-behavior: smooth;
}

a {
  color: var(--main-accent-color);
  text-decoration: none;
}

.smartbanner-top {
  top: -80px;
}

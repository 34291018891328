.react-autosuggest__container {
  position: relative;
  width: 100%;
  background-color: var(--main-background-color);
  user-select: none;
}

.react-autosuggest__container *:not(input) {
  user-select: none;
}

.react-autosuggest__input {
  width: 100%;
  max-width: calc(100% - 72px);
  height: 56px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid var(--border-color);
  border-radius: 0;
  outline: none;
  transition: border 0.2s;
  appearance: none;
}

.react-autosuggest__input::placeholder {
  color: var(--tertiary-text-color);
  opacity: 1;
  transition: color 0.2s;
}

.react-autosuggest__suggestions-container {
  width: 100%;
  max-width: 100%;
  background-color: var(--main-background-color);
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.react-autosuggest__suggestion {
  min-height: 56px;
  padding: 1rem;
  cursor: pointer;
  transition: color 0.2s;
  border-bottom: 1px solid var(--border-color);
}

.react-autosuggest__suggestion--highlighted {
  color: var(--main-accent-color);
}
